<script lang="ts">
	import { SliceZone } from '@prismicio/svelte';
	import { components } from '$lib/slices';
	import { TriggerHeaderColor } from '$lib/helpers/color.trigger';
	import { onDestroy, onMount } from 'svelte';
	import { hideFooter } from '$lib/variable.store';
	hideFooter.set('false');

	export let data;

	let windowDocument: Document;

	onMount(() => {
		windowDocument = document;

		if (data.page.data.header_content_color) {
			TriggerHeaderColor(data.page.data.header_content_color);
		}
	});

	onDestroy(() => {
		if (windowDocument !== undefined) {
			windowDocument.body.setAttribute('style', '');
			windowDocument.body.classList.remove('color-trigger');
		}
	});
</script>

<h1 class="visually-hidden">{data.meta_title}</h1>

<SliceZone slices={data.page.data.slices} {components} />
